import React, { useState, createRef, useEffect } from "react";
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2'
import config from "../../config"


import ReactLoading from 'react-loading';
import { useNetworkState } from 'react-use';
import { Barcode, Boxes, Calculator, Code, Cpu, FileText, Home, LocateFixed, PackageX, Receipt, RotateCcw, ShoppingBag, Store, Truck, User, Warehouse, Weight } from "lucide-react";


const Left_menu = ({ isSidebarOpen, toggleSidebar , props }) => {
    let navigate = useNavigate()
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
  let userDetail = localStorage.getItem('ship_rocket_user');
  const[balancestate,setbalanceState]= React.useState({total_balance:[]})
  // console.log("userDetail config====", config.UserId);
  const SUPPORT = config.SUPPORT
  
  const [userState, setUserState] = useState({ full_name: "" });
 
  const[arrow,setarrow] = React.useState(0)
 
  const [submenuVisible, setSubmenuVisible] = useState(false);
  const [submenushipmentVisible, setSubmenushipmentVisible] = useState(false);
  const [submenuinventoryVisible, setSubmenuinventoryVisible] = useState(false);

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const [isSubMenuOpenbilling, setIsSubMenuOpenbilling] = useState(false);
  const [isSubMenuOpenreport, setIsSubMenuOpenreport] = useState(false);
  const [isSubMenuOpensetting, setIsSubMenuOpensetting] = useState(false);
  // const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  // const toggleSidebar = () => {
  //     setIsSidebarOpen(!isSidebarOpen);
  // };


    // Function to toggle sub-menu
    const toggleSubMenu = () => {
        setIsSubMenuOpen(!isSubMenuOpen);
    };
    const toggleSubMenubilling = () => {
      setIsSubMenuOpenbilling(!isSubMenuOpenbilling);
  };
  const toggleSubMenureport = () => {
    setIsSubMenuOpenreport(!isSubMenuOpenreport);
};
const toggleSubMenusetting = () => {
  setIsSubMenuOpensetting(!isSubMenuOpensetting);
};
    // Function to navigate to create order page
    const navigateToCreateOrder = () => {
        navigate('/customer/create_order_1?value=0');
    };

  const handleSubMenuClick = () => {
    setSubmenuVisible(!submenuVisible);
  };
  const handleshipmentSubMenuClick = () => {
    setSubmenushipmentVisible(!submenushipmentVisible);
  };
  const handleinventorySubMenuClick = () => {
    setSubmenuinventoryVisible(!submenuinventoryVisible);
  };
  
  let userData = {};
  if (userDetail != null) {
    userData = JSON.parse(userDetail)
  }
  console.log("userData == " , userData)
  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000
  })

  React.useEffect(()=>{
    get_wallet_balance()
    // dimension_detail()
    // user()

  },[])
    const logOutButton = () =>{
        // console.log("userDetail ===>   ");
      
        let full_api = config.apiUrl + `/user/user_logout`;
          let sendData = {customer_id:userData.customer_id};
          // console.log("bb", sendData);
          axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
              // console.log("res ", res);
              if (res.data.status==true) {
                localStorage.removeItem('ship_rocket_user');
                userDetail = null;
                setUserState({full_name:""})
                navigate('/customer/login')
              }else {
                Toast.fire({
                  background:"#c2200a",
                  type: 'unsuccess',
                  title: res.data.message ,
                  color:"white"
                });
                 
              }
      
          }).catch((e) => {
      
      
          });
        
      
      }
      const get_wallet_balance=()=>{
        let dataToSend = {customer_id : userData.customer_id};
       
         // console.log("datatoSend",dataToSend)
         let url = config.apiUrl + '/user/get_customer_wallet';
         axios.post(url, dataToSend, { headers: config.headers })
             .then((res) => {
               // console.log("responseJson => ", res);
               if(res.data.status==true){
           setbalanceState({total_balance:res.data.find_customer_wallet})
        }
       else{
           const Toast = Swal.mixin({
               toast: true,
               position: 'bottom-end',
               showConfirmButton: false,
               timer: 3000
             })
             Toast.fire({
               background:"#c2200a",
               type: 'Unsuccess',
               title: "Something Went Wrong !",
               color:"white"
             });
       } })
           
             .catch((error) => {
               const Toast = Swal.mixin({
                   toast: true,
                   position: 'bottom-end',
                   showConfirmButton: false,
                   timer: 3000
                 })
                 Toast.fire({
                   background:"#c2200a",
                   type: 'Unsuccess',
                   title: "Something Went Wrong !",
                   color:"white"
                 });
                 // console.log(error);
             });    
       
       }
    // let value  = props.value
    // console.log("value ====" , value)

    const arrowset =()=>{
        if(arrow==0){
            setarrow(1)
        }
        else{
            setarrow(0)
        }
    }
    const redirectToShipmentsPage = (statuses) => {
      const statusParam = statuses.join('%2C'); // Join status array with comma and encode
      window.location.href = `/customer/shipments?status=${statusParam}`;
  };
    const handleDeliveredShipmentsClick = () => {
      // Redirect to Shipments page with only status 5 included
      redirectToShipmentsPage([5]);
  };
  const handleBookedShipmentsClick = () => {
    // Redirect to Shipments page with all statuses included
    redirectToShipmentsPage([1, 2, 3, 4, 5,6]);
};
const handleNdrShipmentsClick = () => {
  // Redirect to Shipments page with all statuses included
  redirectToShipmentsPage([8]);
};
const handleRtoShipmentsClick = () => {
  // Redirect to Shipments page with all statuses included
  redirectToShipmentsPage([10]);
};
const handleScheduledShipmentsClick = () => {
  // Redirect to Shipments page with all statuses included
  redirectToShipmentsPage([9]);
};
    
  return (
 
<div>
{/* <div className={`sidebar ${isSidebarOpen ? '' : 'close'}`}>
          <div class="logo-details">
            <div class="head_icon">
                <i class="bx bx-menu" onClick={toggleSidebar}></i>
            </div>
            <span class="logo_name">Sky Ship</span>
          </div>
          <ul class="nav-links">
            <li>
              <div class="iocn-link">
                <a href="/customer/dashboard" >
                  <i class="bx bx-grid-alt"></i>
                  <span class="link_name">Dashboard</span>
                </a>
              
              </div>
              <ul class="sub-menu">
                <li><a class="link_name" href="/customer/dashboard">Dashboard</a></li>
              
              </ul>
            </li>
            {userData.user_type==1?
            <li className={isSubMenuOpen ? 'showMenu' : ''}>
            <div className="iocn-link" onClick={toggleSubMenu}>
                <a >
                    <i className="bx bx-cart"></i>
                    <span className="link_name">Orders</span>
                </a>
                <i className={`bx bxs-chevron-down arrow ${isSubMenuOpen ? 'open' : ''}`} onClick={toggleSubMenu}></i>
            </div>
            
           
                <ul className="sub-menu ">
                    <li ><a className="link_name" href="/customer/orders">Orders</a></li>
                    <li><a href="/customer/orders">B2C Orders</a></li>
                    <li><a href="/customer/b2b_orders">B2B Orders</a></li>

                    <li><a onClick={navigateToCreateOrder} style={{cursor: "pointer"}}>Create new order</a></li>
                    <li><a href="/customer/order_import">Import bulk order</a></li>
                </ul>
           
        </li>
        :userData.user_type==2 && userData.previlage!=undefined && userData.previlage.orders!=false?
        <li className={isSubMenuOpen ? 'showMenu' : ''}>
        <div className="iocn-link" onClick={toggleSubMenu}>
            <a >
                <i className="bx bx-cart"></i>
                <span className="link_name">Orders</span>
            </a>
            <i className={`bx bxs-chevron-down arrow ${isSubMenuOpen ? 'open' : ''}`} onClick={toggleSubMenu}></i>
        </div>
        
       
            <ul className="sub-menu ">
                <li ><a className="link_name" href="/customer/orders">Orders</a></li>
                <li><a href="/customer/orders">B2C Orders</a></li>
                <li><a href="/customer/b2b_orders">B2B Orders</a></li>

                <li><a onClick={navigateToCreateOrder} style={{cursor: "pointer"}}>Create new order</a></li>
                <li><a href="/customer/order_import">Import bulk order</a></li>
            </ul>
       
    </li>:""}

    {userData.user_type==1?
            <li className={submenushipmentVisible ? 'showMenu' : ''}>
              <div class="iocn-link" onClick={handleshipmentSubMenuClick}>
                <a href="#">
                  <i class="bx bx-package"></i>
                  <span class="link_name">Shipments</span>
                </a>
                <i className={`bx bxs-chevron-down arrow ${submenushipmentVisible ? 'open' : ''}`} onClick={handleshipmentSubMenuClick}></i>
              </div>
             
              <ul class="sub-menu">
                <li><a class="link_name" href="/customer/shipments">Shipments</a></li>
                <li><a href="/customer/shipments">All Shipments</a></li>
                <li><a onClick={handleBookedShipmentsClick}>Booked Shipments</a></li>
                <li><a onClick={handleDeliveredShipmentsClick}>Delivered Shipments</a></li>
                <li><a onClick={handleNdrShipmentsClick}>NDR Shipments</a></li>
                <li><a  onClick={handleRtoShipmentsClick}>RTO Shipments</a></li>
                <li><a  onClick={handleScheduledShipmentsClick}>Scheduled Shipments</a></li>
              </ul>
             
            </li>
           : userData.user_type==2 && userData.previlage.shipments!=undefined && userData.previlage.shipments!=false?
           <li className={submenushipmentVisible ? 'showMenu' : ''}>
              <div class="iocn-link" onClick={handleshipmentSubMenuClick}>
                <a href="#">
                  <i class="bx bx-package"></i>
                  <span class="link_name">Shipments</span>
                </a>
                <i className={`bx bxs-chevron-down arrow ${submenushipmentVisible ? 'open' : ''}`} onClick={handleshipmentSubMenuClick}></i>
              </div>
             
              <ul class="sub-menu">
                <li><a class="link_name" href="/customer/shipments">Shipments</a></li>
                <li><a href="/customer/shipments">All Shipments</a></li>
                <li><a onClick={handleBookedShipmentsClick}>Booked Shipments</a></li>
                <li><a onClick={handleDeliveredShipmentsClick}>Delivered Shipments</a></li>
                <li><a onClick={handleNdrShipmentsClick}>NDR Shipments</a></li>
                <li><a  onClick={handleRtoShipmentsClick}>RTO Shipments</a></li>
                <li><a  onClick={handleScheduledShipmentsClick}>Scheduled Shipments</a></li>
              </ul>
             
            </li>
           :""}
            
            {userData.user_type==1?
            <li>
              <a href="/customer/warehouse">
                <i class="bx bx-building-house"></i>
                <span class="link_name">Warehouse</span>
              </a>
              <ul class="sub-menu blank" >
                <li><a class="link_name" href="/customer/warehouse">Warehouse</a></li>
              </ul>
            </li>
            :userData.user_type==2 && userData.previlage.warehouse!=undefined && userData.previlage.warehouse!=false?
            <li>
            <a href="/customer/warehouse">
              <i class="bx bx-building-house"></i>
              <span class="link_name">Warehouse</span>
            </a>
            <ul class="sub-menu blank" >
              <li><a class="link_name" href="/customer/warehouse">Warehouse</a></li>
            </ul>
          </li>
            :""}

            <li className={submenuinventoryVisible ? 'showMenu' : ''}>
              <div class="iocn-link" onClick={handleinventorySubMenuClick}>
                <a href="#">
                  <i class="bx bx-store"></i>
                  <span class="link_name">Inventory Management</span>
                </a>
                <i className={`bx bxs-chevron-down arrow ${submenuinventoryVisible ? 'open' : ''}`} onClick={handleinventorySubMenuClick}></i>
              </div>
              <ul class="sub-menu">
                <li><a class="link_name" href="">Inventory Management</a></li>
                <li><a href="/customer/warehouse_inventory">Inventory Management</a></li>
                <li><a href="/customer/product_list">Product Management</a></li>
                <li><a href="/customer/stock_report">Stock Report</a></li>

                
              </ul>
            </li>

{userData.user_type==1?
            <li>
              <a href="/customer/weight_discrepancies_list">
                <i class="bx bx-scatter-chart"></i>
                <span class="link_name">Weight Discrepancy</span>
              </a>
              <ul class="sub-menu blank" >
                <li><a class="link_name" href="/customer/weight_discrepancies_list">Weight Discrepancy</a></li>
              </ul>
            </li>
            :userData.user_type==2 && userData.previlage.weight_discrepancy!=undefined && userData.previlage.weight_discrepancy!=false?
<li>
              <a href="/customer/weight_discrepancies_list">
                <i class="bx bx-scatter-chart"></i>
                <span class="link_name">Weight Discrepancy</span>
              </a>
              <ul class="sub-menu blank" >
                <li><a class="link_name" href="/customer/weight_discrepancies_list">Weight Discrepancy</a></li>
              </ul>
            </li>
            :""}

{userData.user_type==1?
            <li className={submenuVisible ? 'showMenu' : ''}>
              <div class="iocn-link">
                <a onClick={handleSubMenuClick}>
                  <i class="bx bx-sushi"></i>
                  <span class="link_name">Tools</span>
                </a>
                <i className={`bx bxs-chevron-down arrow ${submenuVisible ? 'open' : ''}`} onClick={handleSubMenuClick}></i>
              </div>
            
              <ul class="sub-menu">
              
                <li><a href="/customer/rate_calculator">Rate Calculator</a></li>
                <li><a href="/customer/allocation">Order Allocation Engine</a></li>
                
                <li><a href="/customer/aftership">AfterShip</a></li>
                <li><a href="/customer/boxes">Boxes</a></li>
                <li><a href="/customer/pickup_request">Pickup Request</a></li>

              </ul>
             
            </li>
 :userData.user_type==2 && userData.previlage.tools!=undefined && userData.previlage.tools!=false?
 <li className={submenuVisible ? 'showMenu' : ''}>
 <div class="iocn-link">
   <a onClick={handleSubMenuClick}>
     <i class="bx bx-sushi"></i>
     <span class="link_name">Tools</span>
   </a>
   <i className={`bx bxs-chevron-down arrow ${submenuVisible ? 'open' : ''}`} onClick={handleSubMenuClick}></i>
 </div>

 <ul class="sub-menu">
 
   <li><a href="/customer/rate_calculator">Rate Calculator</a></li>
   <li><a href="/customer/allocation">Order Allocation Engine</a></li>
  
   <li><a href="/customer/aftership">AfterShip</a></li>
   <li><a href="/customer/boxes">Boxes</a></li>
   <li><a href="/customer/pickup_request">Pickup Request</a></li>

   

 </ul>

</li>:""}

{userData.user_type==1?
            <li className={isSubMenuOpenbilling ? 'showMenu' : ''}>
              <div class="iocn-link">
                <a onClick={toggleSubMenubilling}>
                  <i class="bx bx-receipt"></i>
                  <span class="link_name">Billing</span>
                </a>
                <i className={`bx bxs-chevron-down arrow ${isSubMenuOpenbilling ? 'open' : ''}`} onClick={toggleSubMenubilling}></i>
              </div>
           
              <ul class="sub-menu">
                
                <li><a href="/customer/billing_shipping_charges">Shipping Charges</a></li>
                <li><a href="/customer/billing_cod_remittance">COD Remittance</a></li>
                <li><a href="/customer/billing_wallet">Wallet History</a></li>
              </ul>
             
            </li>
            :userData.user_type==2 && userData.previlage.billing!=undefined && userData.previlage.billing!=false?
            <li className={isSubMenuOpenbilling ? 'showMenu' : ''}>
              <div class="iocn-link">
                <a onClick={toggleSubMenubilling}>
                  <i class="bx bx-receipt"></i>
                  <span class="link_name">Billing</span>
                </a>
                <i className={`bx bxs-chevron-down arrow ${isSubMenuOpenbilling ? 'open' : ''}`} onClick={toggleSubMenubilling}></i>
              </div>
           
              <ul class="sub-menu">
              
                <li><a href="/customer/billing_shipping_charges">Shipping Charges</a></li>
                <li><a href="/customer/billing_cod_remittance">COD Remittance</a></li>
                <li><a href="/customer/billing_wallet">Wallet History</a></li>
              </ul>
             
            </li> :""}

            {userData.user_type==1?
            <li>
              <a href="/customer/tickets">
                <i class="bx bx-support"></i>
                <span class="link_name">Support</span>
              </a>
              <ul class="sub-menu blank" >
                <li><a class="link_name" href="/customer/tickets">Support</a></li>
                
              </ul>
            </li>

:userData.user_type==2 && userData.previlage.support!=undefined && userData.previlage.support!=false?
<li>
<a href="/customer/tickets">
  <i class="bx bx-support"></i>
  <span class="link_name">Support</span>
</a>
<ul class="sub-menu blank" >
  <li><a class="link_name" href="/customer/tickets">Support</a></li>
  
</ul>
</li>
:""}

    {userData.user_type==1?
            <li  className={isSubMenuOpenreport ? 'showMenu' : ''}>
              <div class="iocn-link">
                <a onClick={toggleSubMenureport}>
                  <i class="bx bx-receipt"></i>
                  <span class="link_name">Reports</span>
                </a>
                <i className={`bx bxs-chevron-down arrow ${isSubMenuOpenbilling ? 'open' : ''}`} onClick={toggleSubMenureport}></i>
              </div>
             
              <ul class="sub-menu" >
               
                <li><a href="/customer/reports">Shipment Reports</a></li>
                <li><a  href="/customer/invoices">Invoices</a></li>
              </ul>
            
            </li>
           :userData.user_type==2 && userData.previlage.reports!=undefined && userData.previlage.reports!=false?
           <li  className={isSubMenuOpenreport ? 'showMenu' : ''}>
           <div class="iocn-link">
             <a onClick={toggleSubMenureport}>
               <i class="bx bx-receipt"></i>
               <span class="link_name">Reports</span>
             </a>
             <i className={`bx bxs-chevron-down arrow ${isSubMenuOpenbilling ? 'open' : ''}`} onClick={toggleSubMenureport}></i>
           </div>
          
           <ul class="sub-menu" >
           
             <li><a href="/customer/reports">Shipment Reports</a></li>
             <li><a  href="/customer/invoices">Invoices</a></li>
           </ul>
         
         </li>:""}

         {userData.user_type==1?
            <li className={isSubMenuOpensetting ? 'showMenu' : ''}>
              <div class="iocn-link">
                <a onClick={toggleSubMenusetting}>
                  <i class="bx bx-cog"></i>
                  <span class="link_name">Settings</span>
                </a>
                <i className={`bx bxs-chevron-down arrow ${isSubMenuOpensetting ? 'open' : ''}`} onClick={toggleSubMenusetting}></i>
              </div>
            
              <ul class="sub-menu" >
                <li><a class="link_name" >Settings</a></li>
                <li><a class="" href="/customer/edit_profile">Profile</a></li>
                 <li><a class="" href="/customer/staff">Staff</a></li> 
                <li><a href="/customer/channel_list">Channel Integration</a></li>
                <li><a href="/customer/label_settings">Lable Settings</a></li>
                <li><a href="/customer/customer_kyc">Customer KYC</a></li>
                <li><a href="/customer/customer_bank">Bank Information</a></li>
                <li><a href="/customer/email_notifications">Notifications</a></li>
              
              </ul>
            
            </li>

 :userData.user_type==2 && userData.previlage.settings!=undefined && userData.previlage.settings!=false?
 <li className={isSubMenuOpensetting ? 'showMenu' : ''}>
 <div class="iocn-link">
   <a onClick={toggleSubMenusetting}>
     <i class="bx bx-cog"></i>
     <span class="link_name">Settings</span>
   </a>
   <i className={`bx bxs-chevron-down arrow ${isSubMenuOpensetting ? 'open' : ''}`} onClick={toggleSubMenusetting}></i>
 </div>

 <ul class="sub-menu" >
   <li><a class="link_name" >Settings</a></li>
   <li><a class="" href="/customer/edit_profile">Profile</a></li>
    <li><a class="" href="/customer/staff">Staff</a></li> 
   <li><a href="/customer/channel_list">Channel Integration</a></li>
   <li><a href="/customer/label_settings">Lable Settings</a></li>
   <li><a href="/customer/customer_kyc">Customer KYC</a></li>
   <li><a href="/customer/customer_bank">Bank Information</a></li>
   <li><a href="/customer/email_notifications">Notifications</a></li>
 </ul>

</li>
:""}

            <li>
            <div class="profile-details">
                <div class="profile-content">
                <img src="/img/profile.png" alt="profileImg"/>
                </div>
                <div class="name-job">
                <div class="profile_name">{userData.full_name}</div>
                <div class="job">{userData.company_name?userData.company_name:""} </div>
                </div>
                <i class="bx bx-log-out" onClick={() => logOutButton()}></i>
            </div>
        </li>
      </ul>
    </div> */}

<aside class="sidebar ">
   <div class="sidebar-header">
      <img src="/img/logo.png" alt="logo" />
      <a href="/customer/dashboard"></a>
   </div>
   <ul class="sidebar-links">
      <h4>
         <span>Main Menu</span>
         <div class="menu-separator"></div>
      </h4>
     
      <li class="active">
         <a href="/customer/dashboard"><i class='bx bx-grid-alt' ></i>Dashboard</a>
      </li>

      {userData.user_type==1?
        <li class="submenu ">
          <a href="/customer/orders">
          <i class='bx bx-cart' ></i> Orders 
          <span><i class='bx bx-chevron-down' ></i></span>
          </a>
          <ul class="submenu-links">
            <li class="active"><a href="/customer/orders" class="active"><i class='bx bx-circle' ></i>Orders</a></li>
            <li><a onClick={navigateToCreateOrder} style={{cursor: "pointer"}}><i class='bx bx-circle' ></i>Create new order</a></li>
            <li><a href="/customer/order_import"><i class='bx bx-circle' ></i> Import bulk order</a></li>
           
          </ul>
        </li>
        :  userData.user_type==2 &&userData.previlage&& userData.previlage.orders!=undefined && userData.previlage.orders!=false? 
        <li class="submenu ">
        <a href="/customer/orders">
        <i class='bx bx-cart'></i> Orders 
        <span><i class='bx bx-chevron-down' ></i></span>
        </a>
        <ul class="submenu-links">
        <li class="active"><a href="/customer/orders" class="active"><i class='bx bx-circle' ></i>Orders</a></li>
            <li><a onClick={navigateToCreateOrder} style={{cursor: "pointer"}}><i class='bx bx-circle' ></i>Create new order</a></li>
            <li><a href="/customer/order_import"><i class='bx bx-circle' ></i> Import bulk order</a></li>
           
        </ul>
      </li>
      :""}
     
      {userData.user_type==1?
        <li class="submenu ">
          <a href="/customer/shipments">
          <i class='bx bx-package' ></i> Shipments 
          <span><i class='bx bx-chevron-down' ></i></span>
          </a>
          <ul class="submenu-links">
            <li class="active"><a href="/customer/shipments" class="active"><i class='bx bx-circle' ></i> All Shipments</a></li>
            <li><a onClick={handleBookedShipmentsClick}><i class='bx bx-circle' ></i> Booked Shipments</a></li>
            <li><a onClick={handleDeliveredShipmentsClick}><i class='bx bx-circle' ></i> Delivered Shipments</a></li> 
             <li><a onClick={handleNdrShipmentsClick}><i class='bx bx-circle' ></i> NDR Shipments</a></li> 
              <li><a onClick={handleRtoShipmentsClick}><i class='bx bx-circle' ></i> RTO Shipments</a></li> 
               <li><a onClick={handleScheduledShipmentsClick}><i class='bx bx-circle' ></i> Scheduled Shipments</a></li> 
          
          </ul>
        </li>
        :  userData.user_type==2 &&userData.previlage&& userData.previlage.shipments!=undefined && userData.previlage.shipments!=false? 
        <li class="submenu ">
        <a href="/customer/shipments">
        <i class='bx bx-package' ></i> Shipments 
        <span><i class='bx bx-chevron-down' ></i></span>
        </a>
        <ul class="submenu-links">
        <li class="active"><a href="/customer/shipments" class="active"><i class='bx bx-circle' ></i> All Shipments</a></li>
            <li><a onClick={handleBookedShipmentsClick}><i class='bx bx-circle' ></i> Booked Shipments</a></li>
            <li><a onClick={handleDeliveredShipmentsClick}><i class='bx bx-circle' ></i> Delivered Shipments</a></li> 
             <li><a onClick={handleNdrShipmentsClick}><i class='bx bx-circle' ></i> NDR Shipments</a></li> 
              <li><a onClick={handleRtoShipmentsClick}><i class='bx bx-circle' ></i> RTO Shipments</a></li> 
               <li><a onClick={handleScheduledShipmentsClick}><i class='bx bx-circle' ></i> Scheduled Shipments</a></li> 
        </ul>
      </li>
      :""}

{userData.user_type==1?
      <li>
         <a href="/customer/warehouse"><i class='bx bx-building-house' ></i>Warehouse</a>
      </li>
       : userData.user_type==2 &&userData.previlage&& userData.previlage.warehouse!=undefined && userData.previlage.warehouse!=false?

       <li>
         <a href="/customers/warehouse"><i class='bx bx-building-house' ></i>Warehouse</a>
      </li>
      :""}

{userData.user_type==1?
      <li>
         <a href="/customer/weight_discrepancies_list"><i class='bx bx-scatter-chart' ></i>Weight Reconcillation</a>
      </li>
       : userData.user_type==2 &&userData.previlage&& userData.previlage.weight_discrepancy!=undefined && userData.previlage.weight_discrepancy!=false?

       <li>
         <a href="/customer/weight_discrepancies_list"><i class='bx bx-scatter-chart' ></i>Weight Reconcillation</a>
      </li>
      :""}
       {userData.user_type==1?
      <h4>
         <span>Billing</span>
         <div class="menu-separator"></div>
      </h4>
      : userData.user_type==2 &&userData.previlage&& userData.previlage.billing!=undefined && userData.previlage.billing!=false?
      <h4>
         <span>Master</span>
         <div class="menu-separator"></div>
      </h4>
      :""}

{userData.user_type==1?
        <li class="submenu">
          <a href="/customer/billing_shipping_charges">
          <i class='bx bx-receipt' ></i> Billing
          <span><i class='bx bx-chevron-down' ></i></span>
          </a>
          <ul class="submenu-links">
         
            <li><a href="/customer/billing_shipping_charges"><i class='bx bx-circle' ></i> Shipping Charges</a></li>
            <li><a href="/customer/billing_cod_remittance"><i class='bx bx-circle' ></i> COD Remittance</a></li>
            <li><a href="/customer/billing_wallet"><i class='bx bx-circle' ></i> Wallet History</a></li>
           
          </ul>
        </li>
        : userData.user_type==2 &&userData.previlage&& userData.previlage.billing!=undefined && userData.previlage.billing!=false?
         <li class="submenu">
         <a href="/customer/billing_shipping_charges">
         <i class='bx bx-hive' ></i> Billing
         <span><i class='bx bx-chevron-down' ></i></span>
         </a>
         <ul class="submenu-links">
         <li><a href="/customer/billing_shipping_charges"><i class='bx bx-circle' ></i> Shipping Charges</a></li>
            <li><a href="/customer/billing_cod_remittance"><i class='bx bx-circle' ></i> COD Remittance</a></li>
            <li><a href="/customer/billing_wallet"><i class='bx bx-circle' ></i> Wallet History</a></li>
          </ul>
       </li>
       :""}

{userData.user_type==1?
<>
<h4>
         <span>Tools</span>
         <div class="menu-separator"></div>
      </h4>
     
    
      <li>
         <a href="/customer/aftership"><i class='bx bx-cylinder' ></i>After Ship</a>
      </li>
      <li>
         <a href="/customer/rate_calculator"><i class='bx bx-calculator' ></i>Rate Calculator</a>

      </li>
      <li><a href="/customer/allocation"><i class='bx bx-cylinder' ></i>Order Allocation Engine</a></li>
      <li><a href="/customer/pickup_request"><i class='bx bx-git-pull-request' ></i>Pickup Request</a></li>
     </> 
      : userData.user_type==2 &&userData.previlage&& userData.previlage.tools!=undefined && userData.previlage.tools!=false?
      <>
       <h4>
      <span>Tools</span>
      <div class="menu-separator"></div>
   </h4>
  
 
   <li>
         <a href="/customer/aftership"><i class='bx bx-cylinder' ></i>After Ship</a>
      </li>
      <li>
         <a href="/customer/rate_calculator"><i class='bx bx-calculator' ></i>Rate Calculator</a>
      </li>
      <li><a href="/customer/allocation"><i class='bx bx-cylinder' ></i>Order Allocation Engine</a></li>
      <li><a href="/customer/pickup_request"><i class='bx bx-git-pull-request' ></i>Pickup Request</a></li>
      </>
    :"" }

 
{userData.user_type==1?
<> <h4>
         <span>Other</span>
         <div class="menu-separator"></div>
      </h4>
     
      
      <li>
         <a href="/customer/tickets"><i class='bx bx-support' ></i>Support</a>
      </li>
      {userData.user_type==1?
       <li class="submenu ">
       <a href="/customer/reports">
       <i class='bx bx-receipt' ></i> Reports 
       <span><i class='bx bx-chevron-down' ></i></span>
       </a>
       <ul class="submenu-links">
     
       <li class="active"><a href="/customer/reports" class="active"><i class='bx bx-circle' ></i> Shipment Reports</a></li>
            <li><a href="/customer/invoices"><i class='bx bx-circle' ></i> Invoices</a></li>
         
       </ul>
     </li>
      
 : userData.user_type==2 &&userData.previlage&& userData.previlage.reports!=undefined && userData.previlage.reports!=false?
 <li class="submenu ">
       <a href="/customer/reports">
       <i class='bx bx-receipt' ></i> Reports 
       <span><i class='bx bx-chevron-down' ></i></span>
       </a>
       <ul class="submenu-links">
     
       <li class="active"><a href="/customer/reports" class="active"><i class='bx bx-circle' ></i> Shipment Reports</a></li>
            <li><a href="/customer/invoices"><i class='bx bx-circle' ></i> Invoices</a></li>
         
       </ul>
     </li>:""}
    
    
      
         <li><a class="" href="/customer/edit_profile"><i class='bx bx-user' ></i>Profile</a></li>

      <li>
         <a href="/customer/customer_kyc"><i class='bx bx-id-card' ></i>Customer KYC</a>
         
      </li>
      <li><a href="/customer/channel_list"><i class='bx bx-id-card' ></i>Channel Integration</a></li>
                <li><a href="/customer/label_settings"><i class='bx bx-label' ></i>Lable Settings</a></li>
                
                <li><a href="/customer/customer_bank"><i class='bx bxs-bank'></i>Bank Information</a></li>
                <li><a href="/customer/email_notifications"><i class='bx bx-bell' ></i>Notifications</a></li>
                <li><a  href="/customer/staff"><i class='bx bx-bell' ></i>Staff Management</a></li> 
                </>
                
                : userData.user_type==2 &&userData.previlage&& userData.previlage.reports!=undefined && userData.previlage.reports!=false?
                <> <h4>
                <span>Other</span>
                <div class="menu-separator"></div>
             </h4>
            
             
             <li>
                <a href="/customer/tickets"><i class='bx bx-support' ></i>Support</a>
             </li>
             
                {/* <li><a class="" href="/customer/edit_profile"><i class='bx bx-user' ></i>Profile</a></li> */}
       
             <li>
                <a href="/customer/customer_kyc"><i class='bx bx-id-card' ></i>Customer KYC</a>
                
             </li>
             <li><a href="/customer/channel_list"><i class='bx bx-id-card' ></i>Channel Integration</a></li>
                       <li><a href="/customer/label_settings"><i class='bx bx-label' ></i>Lable Settings</a></li>
                       
                       <li><a href="/customer/customer_bank"><i class='bx bxs-bank' ></i>Bank Information</a></li>
                       <li><a href="/customer/email_notifications"><i class='bx bx-bell' ></i>Notifications</a></li></>:""}

      
 

   </ul>
</aside>
</div>
  )
}

export default Left_menu
